class LocationDecode {
    // decide a given location short code and return the full location

    locations = {
        ae: "United Arab Emirates",
        af: "Afghanistan",
        al: "Albania",
        am: "Armenia",
        ao: "Angola",
        ar: "Argentina",
        at: "Austria",
        au: "Australia",
        az: "Azerbaijan",
        ba: "Bosnia and Herzegovina",
        bd: "Bangladesh",
        be: "Belgium",
        bf: "Burkina Faso",
        bg: "Bulgaria",
        bi: "Burundi",
        bj: "Benin",
        bn: "Brunei Darussalam",
        bo: "Bolivia",
        br: "Brazil",
        bs: "Bahamas",
        bt: "Bhutan",
        bw: "Botswana",
        by: "Belarus",
        bz: "Belize",
        ca: "Canada",
        cd: "Democratic Republic of Congo",
        cf: "Central African Republic",
        cg: "Republic of Congo",
        ch: "Switzerland",
        ci: "Côte d'Ivoire",
        cl: "Chile",
        cm: "Cameroon",
        cn: "China",
        co: "Colombia",
        cr: "Costa Rica",
        cu: "Cuba",
        cy: "Cyprus",
        cz: "Czech Republic",
        de: "Germany",
        dj: "Djibouti",
        dk: "Denmark",
        do: "Dominican Republic",
        dz: "Algeria",
        ec: "Ecuador",
        ee: "Estonia",
        eg: "Egypt",
        eh: "Western Sahara",
        er: "Eritrea",
        es: "Spain",
        et: "Ethiopia",
        fk: "Falkland Islands",
        fi: "Finland",
        fj: "Fiji",
        fr: "France",
        ga: "Gabon",
        gb: "United Kingdom",
        ge: "Georgia",
        gf: "French Guiana",
        gh: "Ghana",
        gl: "Greenland",
        gm: "Gambia",
        gn: "Guinea",
        gq: "Equatorial Guinea",
        gr: "Greece",
        gt: "Guatemala",
        gw: "Guinea-Bissau",
        gy: "Guyana",
        hn: "Honduras",
        hr: "Croatia",
        ht: "Haiti",
        hu: "Hungary",
        id: "Indonesia",
        ie: "Ireland",
        il: "Israel",
        in: "India",
        iq: "Iraq",
        ir: "Iran",
        is: "Iceland",
        it: "Italy",
        jm: "Jamaica",
        jo: "Jordan",
        jp: "Japan",
        ke: "Kenya",
        kg: "Kyrgyzstan",
        kh: "Cambodia",
        kp: "North Korea",
        kr: "South Korea",
        xk: "Kosovo",
        kw: "Kuwait",
        kz: "Kazakhstan",
        la: "Lao People's Democratic Republic",
        lb: "Lebanon",
        lk: "Sri Lanka",
        lr: "Liberia",
        ls: "Lesotho",
        lt: "Lithuania",
        lu: "Luxembourg",
        lv: "Latvia",
        ly: "Libya",
        ma: "Morocco",
        md: "Moldova",
        me: "Montenegro",
        mg: "Madagascar",
        mk: "Macedonia",
        ml: "Mali",
        mm: "Myanmar",
        mn: "Mongolia",
        mr: "Mauritania",
        mw: "Malawi",
        mx: "Mexico",
        my: "Malaysia",
        mz: "Mozambique",
        na: "Namibia",
        nc: "New Caledonia",
        ne: "Niger",
        ng: "Nigeria",
        ni: "Nicaragua",
        nl: "Netherlands",
        no: "Norway",
        np: "Nepal",
        nz: "New Zealand",
        om: "Oman",
        pa: "Panama",
        pe: "Peru",
        pg: "Papua New Guinea",
        ph: "Philippines",
        pl: "Poland",
        pk: "Pakistan",
        pr: "Puerto Rico",
        ps: "Palestinian Territories",
        pt: "Portugal",
        py: "Paraguay",
        qa: "Qatar",
        ro: "Romania",
        rs: "Serbia",
        ru: "Russia",
        rw: "Rwanda",
        sa: "Saudi Arabia",
        sb: "Solomon Islands",
        sd: "Sudan",
        se: "Sweden",
        si: "Slovenia",
        sj: "Svalbard and Jan Mayen",
        sk: "Slovakia",
        sl: "Sierra Leone",
        sn: "Senegal",
        so: "Somalia",
        sr: "Suriname",
        ss: "South Sudan",
        sv: "El Salvador",
        sy: "Syria",
        sz: "Swaziland",
        td: "Chad",
        tf: "French Southern and Antarctic Lands",
        tg: "Togo",
        th: "Thailand",
        tj: "Tajikistan",
        tl: "Timor-Leste",
        tm: "Turkmenistan",
        tn: "Tunisia",
        tr: "Turkey",
        tt: "Trinidad and Tobago",
        tw: "Taiwan",
        tz: "Tanzania",
        ua: "Ukraine",
        ug: "Uganda",
        us: "United States",
        uy: "Uruguay",
        uz: "Uzbekistan",
        ve: "Venezuela",
        vn: "Vietnam",
        vu: "Vanuatu",
        ye: "Yemen",
        za: "South Africa",
        zm: "Zambia",
        zw: "Zimbabwe"
    };

    decode(location) {
        if (location in this.locations) {
            return this.locations[location];
        }
        return 'Unknown';
    }
}

export default new LocationDecode();
